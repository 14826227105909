import React from 'react'
import Link from 'next/link'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'

import Image, { type ImagePropsType } from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'

type HomeCardCataloguePropType = {
  title: string
  image: string
  isCategory?: boolean
  boxPropsContainer?: BoxPropsType
  imageProps?: Omit<ImagePropsType, 'src' | 'alt' | 'fallbackImageSrc'>
  slug: string
  onClick?: (item: { title: string; isCategory: boolean }) => void
}

const CategoryCatalogueCard = ({
  title,
  image,
  isCategory,
  boxPropsContainer,
  imageProps,
  slug,
  onClick,
}: HomeCardCataloguePropType) => (
  <Link
    href={isCategory ? `/c/${slug}` : `/cat/${slug}`}
    onClick={() => {
      if (onClick) {
        onClick({ title, isCategory: isCategory || false })
      }
    }}
  >
    <Box
      position="relative"
      boxShadow="0px 0px 12px 0px rgba(100, 100, 100, 0.08), 0px 0px 4px 0px rgba(7, 7, 7, 0.08)"
      borderRadius="8px"
      margin="0 auto"
      {...boxPropsContainer}
    >
      <Typography
        variant="body-tinyStrong"
        position="absolute"
        zIndex={1}
        textAlign="center"
        marginLeft="auto"
        marginRight="auto"
        left="0"
        right="0"
        top="6px"
        color={color.textColor.linkSelected}
        px="4px"
      >
        {title}
      </Typography>
      <Box sx={{ aspectRatio: 1 }}>
        <Image
          alt="image-catalogue"
          src={image}
          fallbackImageSrc={`${ASSET_PREFIX}/img/placeholder-product.svg`}
          style={{
            borderRadius: '8px',
            objectFit: 'cover',
            width: '100%',
          }}
          fill
          {...imageProps}
        />
      </Box>
    </Box>
  </Link>
)

export default CategoryCatalogueCard
