import { type GetBannerContentType } from 'utils/apiList/lite/banner'
import { getMetaAstroDeeplinkUrl } from 'app/(home)/_config/banner'

export const getComposedBannerURL = (item: GetBannerContentType) => {
  const url = item.banner_url_link || ''

  if (url && url.includes('https')) {
    return url
  }

  const { targetUrl } = getMetaAstroDeeplinkUrl(item)

  return targetUrl
}
