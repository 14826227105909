'use client'

import { createContext, useContext, useMemo } from 'react'
import dynamic from 'next/dynamic'
import { CatalogueV2Placeholder } from 'app/(home)/_components/Placeholders'
import { CatalogueSectionPropsType } from 'app/(home)/_components/CatalogueSection/types'
import { CatalogueV2PropsType } from 'app/(home)/_components/CatalogueV2/CatalogueV2'

const CatalogueContext = createContext<Nullable<CatalogueSectionPropsType>>(null)

const Fallback = () => {
  const ctx = useContext(CatalogueContext)
  return <CatalogueV2Placeholder {...ctx} />
}

const CatalogueSection = dynamic(() => import('app/(home)/_components/CatalogueV2/CatalogueV2'), {
  ssr: false,
  loading: () => <Fallback />,
})

const CatalogueSectionContainer = ({
  boxProps,
  initialData,
  initialBigCatalogue,
}: CatalogueV2PropsType) => {
  const context = useMemo(
    () => ({ initialData, boxProps, initialBigCatalogue }),
    [initialData, boxProps, initialBigCatalogue],
  )
  return (
    <CatalogueContext.Provider value={context}>
      <CatalogueSection
        initialData={initialData}
        boxProps={boxProps}
        initialBigCatalogue={initialBigCatalogue}
      />
    </CatalogueContext.Provider>
  )
}

export default CatalogueSectionContainer
