'use client'

import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import { ChevronDown, CustomerService } from '@astronautsid/wpe-icons'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import GlobalSearchBar from 'components/GlobalSearchBar'

import AlertErrorAddress from 'components/AlertErrorAddress'
import { mixpanelEventCategory } from 'config/mixpanelConstants'
import useHomeHeader from './_hooks/useHomeHeader'
import { HeaderStyled } from './style'

type HomeHeaderPropsType = {
  isGuestMode?: boolean
  textColor?: string
  handleTickerShown: (status: boolean) => void
}

const HomeHeader = ({
  textColor = 'inherit',
  handleTickerShown,
  isGuestMode = false,
}: HomeHeaderPropsType) => {
  const theme = useTheme()
  const { addressLabel, isLoadingAddress, handleChangeAddress, handleClickChatCS } =
    useHomeHeader(isGuestMode)

  const renderAddressInfo = () => {
    if (isLoadingAddress) {
      return (
        <Box display="flex" flexDirection="column" gap="2px">
          <Skeleton width="150px" height="22px" variant="rounded" />
          <Skeleton width="70%" height="18px" variant="rounded" />
          <Box mt="8px">
            <Skeleton width="50%" height="30px" variant="rounded" />
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Box position="relative" minHeight="42px">
          <Box sx={{ width: 'calc(100% - 95px)' }}>
            <Box
              display="inline-flex"
              gap="4px"
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={handleChangeAddress}
            >
              <Typography variant="body-defaultStrong" color={textColor}>
                {addressLabel.label}
              </Typography>
              <ChevronDown size={16} color={textColor} />
            </Box>
            {!!addressLabel.detail && (
              <Box style={{ cursor: 'pointer' }} onClick={handleChangeAddress}>
                <Typography
                  variant="caption-default"
                  color={textColor}
                  component="p"
                  width="100%"
                  display="inline-block"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {addressLabel.detail}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            onClick={handleClickChatCS}
            style={{ cursor: 'pointer' }}
            position="absolute"
            right={0}
            top={0}
            bgcolor={theme.palette.bgColor.light}
            borderRadius={999}
            p="4px 8px"
            pl="4px"
            display="inline-flex"
            alignItems="center"
            gap="4px"
          >
            <CustomerService type="outline" size={22} />
            <Typography variant="body-tiny">Chat CS</Typography>
          </Box>
        </Box>
        <AlertErrorAddress handleTickerShown={handleTickerShown} />
      </>
    )
  }

  return (
    <HeaderStyled px="16px" py="12px">
      {renderAddressInfo()}
      <Box display="flex" gap="8px" alignItems="center" mb="18px" mt="8px">
        <GlobalSearchBar boxProps={{ flex: 1 }} source={mixpanelEventCategory.HOME_PAGE} />
      </Box>
    </HeaderStyled>
  )
}

export default HomeHeader
