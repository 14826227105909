'use client'

import { useContext, Fragment } from 'react'

import { NewBannerContext } from 'app/(home)/_components/NewHomeHeader/NewBannerSection/BannerProvider'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { ASSET_PREFIX } from 'config/constants'
import Image from 'components/Image'
import {
  NEW_BANNER_ASPECT_RATIO,
  NEW_BANNER_WIDTH,
  NEW_BANNER_HEIGHT,
  NEW_MAX_WIDTH_BANNER_CONTAINER,
} from 'app/(home)/_config/banner'
import { getComposedBannerURL } from 'utils/url/bannerUrl'

const NewBannerPlaceholder = () => {
  const bannerContext = useContext(NewBannerContext)
  const boxProps = bannerContext?.boxProps || {}
  const banners = bannerContext?.banners || []
  const tickerShown = !!bannerContext?.tickerShown

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        aspectRatio: NEW_BANNER_ASPECT_RATIO,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'block',
      }}
    >
      {banners?.map((banner, i) => (
        <Fragment key={banner.banner_id}>
          <Box
            component="a"
            sx={{
              position: i < 1 ? 'relative' : 'absolute',
              overflow: 'hidden',
              width: 'calc(100% - 16px)',
              transform: i > 0 ? 'translateX(-1000%)' : 'none',
            }}
            {...boxProps}
            {...NEW_MAX_WIDTH_BANNER_CONTAINER}
            href={getComposedBannerURL(banner) || ''}
          >
            <Image
              src={banner.banner_url_image || `${ASSET_PREFIX}/img/placeholder-product.svg`}
              alt={banner.banner_name}
              containerProps={{
                sx: {
                  aspectRatio: tickerShown
                    ? NEW_BANNER_WIDTH / (NEW_BANNER_HEIGHT + 55)
                    : NEW_BANNER_WIDTH / NEW_BANNER_HEIGHT,
                  position: 'relative',
                  maxHeight: !tickerShown
                    ? NEW_BANNER_HEIGHT
                    : `calc(${NEW_BANNER_HEIGHT}px + 55px)`,
                  width: '100%',
                },
              }}
              style={{
                objectFit: 'cover',
                objectPosition: 'bottom',
                width: '100%',
                height: '100%',
              }}
              fill
              priority
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

export default NewBannerPlaceholder
