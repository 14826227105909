import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import CatalogueSkeleton from 'app/(home)/_components/CatalogueV2/LoadingComponent'
import NewBannerSkeleton from 'app/(home)/_components/NewHomeHeader/NewBannerSection/BannerSkeleton'
import CategorySkeleton from 'app/(home)/_components/Placeholders/CategorySkeleton'

/* Some component loader set to double, do not fret, these are intentional to prevent CLS */
const DefaultHomepageSkeleton = () => (
  <Box display="flex" flexDirection="column">
    <NewBannerSkeleton />
    <Box pt="16px">
      <CatalogueSkeleton />
    </Box>
    <Box pt="16px">
      <CategorySkeleton />
    </Box>
    <Box pt="16px">
      <CategorySkeleton />
    </Box>
  </Box>
)

export default DefaultHomepageSkeleton
