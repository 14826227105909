'use client'

import dynamic from 'next/dynamic'
import { BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import useQueryBanner from 'app/(home)/_hooks/useQueryBanner'
import { type GetBannerDataType } from 'utils/apiList/lite/banner'

import BannerContextProvider from 'app/(home)/_components/NewHomeHeader/NewBannerSection/BannerProvider'
import NewBannerPlaceholder from 'app/(home)/_components/NewHomeHeader/NewBannerSection/Placeholder'

import NewBannerSkeleton from './BannerSkeleton'

const BannerSliderSection = dynamic(() => import('./NewBannerSliderSection'), {
  ssr: false,
  loading: () => <NewBannerPlaceholder />,
})

type BannerSectionPropsType = {
  boxProps?: BoxPropsType
  initialData?: GetBannerDataType
  tickerShown?: boolean
  handleChangeTextColor: (color: string) => void
}

const NewBannerSection = ({
  boxProps,
  initialData,
  handleChangeTextColor,
  tickerShown = false,
}: BannerSectionPropsType) => {
  const { data: bannerData, isLoading } = useQueryBanner({ initialData })

  const banners = bannerData?.content?.filter((i) => i.banner_url_image) || []

  if (!isLoading && !banners.length) {
    return null
  }

  if (isLoading) {
    return <NewBannerSkeleton />
  }
  return (
    <BannerContextProvider banners={banners} boxProps={boxProps} tickerShown={tickerShown}>
      <BannerSliderSection
        tickerShown={tickerShown}
        handleChangeTextColor={handleChangeTextColor}
        banners={banners}
      />
    </BannerContextProvider>
  )
}

export default NewBannerSection
