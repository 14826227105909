import { useState } from 'react'

const useNewHomeHeader = () => {
  const [tickerShown, setTickerShown] = useState<boolean>(false)
  const [textColor, setTextColor] = useState<string>('inherit')
  const handleChangeTextColor = (color: string) => {
    setTextColor(color)
  }

  const handleTickerShown = (isShown: boolean) => {
    setTickerShown(isShown)
  }

  return {
    tickerShown,
    handleChangeTextColor,
    handleTickerShown,
    textColor,
  }
}

export default useNewHomeHeader
