'use client'

import dynamic from 'next/dynamic'

import {
  CatalogueV2Placeholder,
  CategorySectionPlaceholder,
} from 'app/(home)/_components/Placeholders'

import DynamicChannel from 'app/(home)/_components/DynamicChannel'
import NewHomeHeader from 'app/(home)/_components/NewHomeHeader'
import useUser from 'hooks/useUser'

const CatalogueSection = dynamic(() => import('app/(home)/_components/CatalogueV2/Container'), {
  loading: () => <CatalogueV2Placeholder />,
})

const CategorySection = dynamic(() => import('app/(home)/_components/CategorySection/Container'), {
  loading: () => <CategorySectionPlaceholder />,
})

const DefaultHomepageSlotting = () => {
  const wrapperProps = {
    mt: '16px',
  }

  const { user } = useUser()

  return (
    <>
      <NewHomeHeader isGuestMode={!user} />
      <CatalogueSection boxProps={{ ...wrapperProps }} />
      <CategorySection boxProps={{ ...wrapperProps }} />
      <DynamicChannel boxProps={{ ...wrapperProps }} />
    </>
  )
}

export default DefaultHomepageSlotting
