'use client'

import { useMemo, createContext, ReactNode } from 'react'
import { BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import type { GetBannerContentType } from 'utils/apiList/lite/banner'

export const NewBannerContext = createContext<
  Nullable<{
    banners: GetBannerContentType[]
    boxProps?: BoxPropsType
    tickerShown?: boolean
  }>
>(null)

const BannerContextProvider = ({
  children,
  banners,
  boxProps,
  tickerShown = false,
}: {
  children: ReactNode
  banners: GetBannerContentType[]
  boxProps?: BoxPropsType
  tickerShown?: boolean
}) => {
  const value = useMemo(
    () => ({ banners, boxProps, tickerShown }),
    [banners, boxProps, tickerShown],
  )

  return <NewBannerContext.Provider value={value}>{children}</NewBannerContext.Provider>
}

export default BannerContextProvider
